// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signOut,
} from "firebase/auth";
import {
    getFirestore
} from "firebase/firestore";

import {
  getFunctions
} from "firebase/functions";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyC5UufkOMVx2BP4Q6sM05z9_LvmfuygIqo",
    authDomain: "myyear-8761e.firebaseapp.com",
    projectId: "myyear-8761e",
    storageBucket: "myyear-8761e.appspot.com",
    messagingSenderId: "662435290424",
    appId: "1:662435290424:web:cfd10cb0cac0b25884b5cd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const func = getFunctions(app);


const googleProvider = new GoogleAuthProvider();
const signInWithGoogle = async () => {
  try {
    await signInWithPopup(auth, googleProvider);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
    signOut(auth);
};

export {
    auth,
    db,
    func,
    signInWithGoogle,
    logout,
  };
  