import './loader.css'
import React, { Component } from 'react'
import jose from './jose.png'
  

export default class Month extends Component {

    init() {

    }

    render() {
        return (
            <div className="loader">
                <img className="loaderImage" src={jose} alt="loader"></img>
                <div className="loaderText">Loading</div>
            </div>
        )
    }
}
