import './month.css'
import React, { Component } from 'react'
import Tooltip from '@mui/material/Tooltip';
import { isAfterToday, isToday } from '../dateUtils';

const HIGHEST_THRESHOLD = 8;
const HIGH_THRESHOLD = 3;
const LOW_THRESHOLD = 1;
  

export default class Month extends Component {

    init() {
        this.dayClicked = this.dayClicked.bind(this)
        this.year = this.props.year
        this.index = parseInt(this.props.index)
        this.data = this.props.data
        this.date = new Date(this.year, this.index + 1, 0)
        this.updateData = this.props.onDataUpdate;
        this.computeDays()
        this.computeTotal()
        this.open = false
        console.log(this.year)
    }

    computeTotal() {
        this.total = Object.values(this.data).reduce((sum, val) => sum + val, 0)
    }

    computeOffset() {
        const firstDay  = new Date(this.date)
        firstDay.setDate(1)
        let dayOfWeek = (((firstDay.getDay()-1) % 7) + 7) % 7
        return [...Array(dayOfWeek)].map((_,i) => <div key={i} className="offset-1"></div>)
    }

    computeDays() {
        this.days = [...Array(this.date.getDate())].map((_, i) => {
            const dayDate = new Date(this.date)
            dayDate.setDate(i + 1)
            const dayData = this.data ? this.data[i + 1] : undefined
            const className = `day ${this.getClassFromValue(dayData)} ${this.getClassFromDay(i)}`
            let title = dayDate.toLocaleString('default', { month: 'long', day: "numeric" });
            if (dayData) title += `(${dayData})`
            return (<Tooltip key={i} title={title}>
                <div className={className} onClick={(e) => this.dayClicked(dayDate, e)}>
                </div>
            </Tooltip>)
        })
    }

    getClassFromDay(i){
        if (isAfterToday(this.year, this.index, i+1)){
            return ' dayDisabled'
        }
        if (isToday(this.year, this.index, i+1)){
            return ' dayToday'
        }
        return ''
    }

    getClassFromValue(value) {
        if (value !== 0 && !value) return 'dayUnknown';
        if (value >= HIGHEST_THRESHOLD) return 'dayDed';
        if (value >= HIGH_THRESHOLD) return 'dayErr';
        if (value >= LOW_THRESHOLD) return 'dayWarn';
        return 'daySuccess';
    }

    dayClicked(date) {
        if (isAfterToday(this.year, this.index, date.getDate() )) return
        const val = parseInt(window.prompt(`Entrer une valeur pour ${date.toLocaleString('default', { month: 'long', day: "numeric" })}`,
            this.data[date.getDate()] ? this.data[date.getDate()] : 0
        ));
        if (Number.isInteger(val) && val >= 0 && val < 100)
            this.updateData(this.props.index, date.getDate(), parseInt(val))
    }

    render() {
        this.init()
        const monthName = this.date.toLocaleString('default', { month: 'long' });
        const offset = this.computeOffset()
        return (
            <div>
                <h2 className="monthName">{monthName} {this.total !== 0 ? <span>({this.total})</span> : ''}</h2>
                <div className={'daysContainer'}>
                    {offset}
                    {this.days}
                </div>
            </div>
        )
    }
}
