import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./dashboard.css";
import { auth, db, func, logout } from "./firebase";
import { query, collection, getDocs, setDoc, doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { initEmptyDataSet } from "./dataUtils";
import Month from './components/month';
import Loader from './components/loader'

const COLLECTION_NAME = 'my_year'
const YEAR_DATA = 'year_data'

let YEAR = new Date().getFullYear().toString();
const scoreboard = httpsCallable(func, 'scoreboard');
const updateUsername = httpsCallable(func, 'setUsername');
const AUTHORIZED_YEARS = []
for (let i=2023; i<= new Date().getFullYear(); i++){
    AUTHORIZED_YEARS.push(i.toString());
}


function Dashboard() {
    const [searchParams] = useSearchParams();
    if (searchParams.get('year') && AUTHORIZED_YEARS.includes(searchParams.get('year'))){
        YEAR = searchParams.get('year')
    }
    const [user, loading, error] = useAuthState(auth);
    const [data, setData] = useState({});
    const [months, setMonths] = useState([]);
    const [year] = useState(YEAR)
    const [stats, setStats] = useState(0)
    const [scores, setScores] = useState([])
    const [username, setUsername] = useState('')
    const [scoreboardLoading, setScoreboardLoading] = useState(true)
    // const [showGraph, setShowGraph] = useState(true)
    const navigate = useNavigate();

    const updateData = async (month, day, value) => {
        const newData = {...data}
        newData[month][day] = value
        await setDoc(doc(db, COLLECTION_NAME, user?.uid, year, YEAR_DATA), newData);
        setData(newData);
    }

    const computeMonths = () => {
        const months = Object.keys(data)
            .sort((a, b) => parseInt(a) - parseInt(b))
            .map((key) => <Month key={key} index={key} year={year} data={data[key]} onDataUpdate={updateData}></Month>)
        setMonths(months)
    }

    const computeStats = () => {
         setStats(Object.values(data).reduce((acc, val) => {
            Object.values(val).forEach(number => {
                if (number === 0){
                    acc += 1 
                }
            })
            return acc
        }, 0))
    }

    const fetchUserData = async () => {
        try {
            const q = query(collection(db, COLLECTION_NAME, user?.uid, year));
            const document = await getDocs(q);
            if (!document.docs.length) {
                let d = initEmptyDataSet();
                await setDoc(doc(db, COLLECTION_NAME, user?.uid), {})
                await setDoc(doc(db, COLLECTION_NAME, user?.uid, year, YEAR_DATA), d)
                setData(d)
            } else {
                const data = document.docs[0].data();
                setData(data)
            }
            const usernameQuery = doc(db, COLLECTION_NAME, user?.uid)
            const usrname = await getDoc(usernameQuery);
            if (usrname.data()?.username){
                console.log('fetched username ', usrname.data()?.username)
                setUsername(usrname.data()?.username)
            } else {
                okUpdateUsername({});
            }
        } catch (err) {
            console.error(err);
        }
    };

    const getScoreboard = async () => {
        setScoreboardLoading(true)
        const scores = await scoreboard({year});
        const sorted = Object.keys(scores.data)
            .map(key => ({key, value: scores.data[key]}))
            .sort((s1, s2) => s1.value - s2.value)
        setScores(sorted)
        setScoreboardLoading(false)
    }

    const changeUsername = (e) => {
        setUsername(e.target.value)
    }

    const okUpdateUsername = async () => {
        await updateUsername({username})
        fetchUserData();
        getScoreboard();
    }

    // const toggleGraph = () => {
    //     setShowGraph(!showGraph)
    // }

    useEffect(() => {
        if (loading) return;
        if (!user || error) return navigate("/");
        fetchUserData();
        getScoreboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, loading, error]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(computeMonths, [data])
    useEffect(computeStats, [data])
    return (
        <div className="dashboard">
            <div className="dashboard__container">
                Logged in as {user?.email} &nbsp;
                <button className="dashboard__btn" onClick={logout}>Logout</button>
                <hr></hr>
                <h2>For the year {year} you had {stats} days alcool free</h2>
                {/*<button className="clear_btn" onClick={clear}>Clear All</button>
                &nbsp;
                */}
                <input className="username_input" maxLength="25" type="text" value={username} onChange={changeUsername}></input>
                &nbsp;<button className="ok_btn" onClick={okUpdateUsername}>Change Username</button>
               {/*&nbsp;<button className="ok_btn" onClick={toggleGraph}>Graph Mode</button>*/}
                <div className="monthContainer">
                    <div className="scoreboard">
                        <h2>Scoreboard</h2>
                        <hr></hr>
                        {scoreboardLoading ? <Loader></Loader> :
                            scores.map((s, i) => <div className="scoreLine" key={i}><div className="username">{s.key}</div><div className="score">{s.value}</div></div>)
                        }
                    </div>
                {months}
                </div>
               
            </div>
        </div>
    );
}
export default Dashboard;