export function getCurrentYear(){
    return new Date().getFullYear()
}

export function isCurrentYear(year){
    return getCurrentYear() === year
}

export function isAfterToday(year, month, day){
    return new Date(year, month, day).setHours(0,0,0,0) > new Date().setHours(0,0,0,0)
}

export function isToday(year, month, day){
    return new Date(year, month, day).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)
}